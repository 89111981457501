<template>
  <section>
    <v-card class="pa-6 bgMinsal elevation-0">
      <v-card-title class="secondary white--text mb-8">
        <span>
          {{
            `Formulario de ${
              $route.meta.type_action == "create"
                ? "nuevo"
                : "actualización de "
            } insumo`
          }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row v-if="$route.meta.type_action == 'create'">
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              label="OBS *"
              placeholder="Seleccione el tipo de OBS"
              outlined
              v-model="tipo_obs_var"
              :items="ctl_tipo_obs_var"
              item-text="nombre"
              item-value="id"
              :error-messages="obsError"
              @blur="$v.tipo_obs_var.$touch"
              @change="
                FetchCtlSegmObjFtn(),
                  (segm_obj_var = null),
                  (producto_var = null)
              "
              clearable
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              :disabled="tipo_obs_var === null"
              label="Segmento / Objeto específico de gasto *"
              placeholder="Seleccione el segmento / objeto específico de gasto"
              outlined
              v-model="segm_obj_var"
              :items="ctl_segm_obj_var"
              item-text="nombre"
              item-value="id"
              :error-messages="segmObjError"
              @blur="$v.segm_obj_var.$touch"
              @change="FetchCtlProductoFtn(), (producto_var = null)"
              clearable
              return-object
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              :disabled="segm_obj_var === null"
              label="Producto *"
              placeholder="Seleccione el producto"
              outlined
              v-model="producto_var"
              :items="ctl_producto_var"
              item-text="nombre"
              item-value="id"
              :error-messages="productoError"
              @blur="$v.producto_var.$touch"
              clearable
            />
          </v-col>
        </v-row>
        <v-row v-else-if="$route.meta.type_action == 'edit'">
          <v-col
            cols="12"
            class="d-flex flex-column flex-sm-row justify-center"
          >
            <div class="px-6">
              <span class="text-h6" v-text="'Código de OBS: '" />
              <span class="text-h6" v-text="info_obs_var.codigo" />
            </div>
            <div class="px-6">
              <span class="text-h6" v-text="'Nombre de OBS: '" />
              <span class="text-h6" v-text="info_obs_var.nombre" />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <v-autocomplete
              label="Unidad de medida *"
              placeholder="Seleccione la unidad de medida"
              outlined
              v-model="unidad_med_var"
              :items="ctl_unidad_med_var"
              item-text="nombre"
              item-value="id"
              :error-messages="unidadMedError"
              @blur="$v.unidad_med_var.$touch"
              clearable
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              label="Cantidad *"
              placeholder="Ingrese la cantidad"
              outlined
              v-model.number="cantidad_var"
              :error-messages="cantidadError"
              @blur="$v.cantidad_var.$touch"
              @input="$v.cantidad_var.$touch"
              clearable
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <money-text-field-component
              ref="money_txt_ref"
              label="Precio unitario *"
              placeholder="Ingrese el precio unitario"
              outlined
              v-model="precio_unit_var"
              :error-messages="precioUnitError"
              @blur="$v.precio_unit_var.$touch"
              @input="$v.precio_unit_var.$touch"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-textarea
            class="mx-3 mt-3"
            label="Especificación *"
            placeholder="Ingrese la especificación del insumo"
            outlined
            auto-grow
            v-model="especificacion_var"
            :error-messages="especificacionError"
            @blur="$v.especificacion_var.$touch"
            @input="$v.especificacion_var.$touch"
            clearable
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center justify-sm-start">
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          outlined
          @click="$router.back()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="secondary"
          dark
          @click="handlerFuntionsFtn"
        >
          {{ $route.meta.type_action == "create" ? "Guardar" : "Actualizar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>
  
  <script>
// importacion de librerias
import {
  required,
  requiredIf,
  numeric,
  helpers,
} from "vuelidate/lib/validators";

// importacion de componentes globales
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  name: "formInsumoView",

  validations: {
    // required if por parametro de route
    tipo_obs_var: {
      required: requiredIf(function () {
        return this.$route.meta.type_action == "create";
      }),
    },
    segm_obj_var: {
      required: requiredIf(function () {
        return this.$route.meta.type_action == "create";
      }),
    },
    producto_var: {
      required: requiredIf(function () {
        return this.$route.meta.type_action == "create";
      }),
    },

    unidad_med_var: { required },
    cantidad_var: { required, numeric },
    precio_unit_var: { required },
    especificacion_var: { required, txtArea },
  },

  components: {
    MoneyTextFieldComponent,
  },

  data: () => ({
    SNAPSHOT_CONST: {},

    // variables
    tipo_obs_var: null,
    segm_obj_var: null,
    producto_var: null,
    unidad_med_var: null,
    cantidad_var: null,
    precio_unit_var: null,
    especificacion_var: null,

    info_obs_var: {},
    id_detalle_var: null,

    // catalogos
    ctl_tipo_obs_var: [],
    ctl_segm_obj_var: [],
    ctl_producto_var: [],
    ctl_unidad_med_var: [],
    id_estado_insumo: null,
  }),
  computed: {
    obsError() {
      const errors = [];
      if (!this.$v.tipo_obs_var.$dirty) return errors;
      !this.$v.tipo_obs_var.required && errors.push("OBS es requerido");
      return errors;
    },
    segmObjError() {
      const errors = [];
      if (!this.$v.segm_obj_var.$dirty) return errors;
      !this.$v.segm_obj_var.required &&
        errors.push("Segmento / Objeto específico de gasto es requerido");
      return errors;
    },
    productoError() {
      const errors = [];
      if (!this.$v.producto_var.$dirty) return errors;
      !this.$v.producto_var.required && errors.push("Producto es requerido");
      return errors;
    },
    unidadMedError() {
      const errors = [];
      if (!this.$v.unidad_med_var.$dirty) return errors;
      !this.$v.unidad_med_var.required &&
        errors.push("Unidad de medida es requerido");
      return errors;
    },
    cantidadError() {
      const errors = [];
      if (!this.$v.cantidad_var.$dirty) return errors;
      !this.$v.cantidad_var.required && errors.push("Cantidad es requerido");
      !this.$v.cantidad_var.numeric &&
        errors.push("Cantidad debe ser numérico");
      return errors;
    },
    precioUnitError() {
      const errors = [];
      if (!this.$v.precio_unit_var.$dirty) return errors;
      !this.$v.precio_unit_var.required &&
        errors.push("Precio unitario es requerido");
      return errors;
    },
    especificacionError() {
      const errors = [];
      if (!this.$v.especificacion_var.$dirty) return errors;
      !this.$v.especificacion_var.required &&
        errors.push("Especificación es requerido");
      !this.$v.especificacion_var.txtArea &&
        errors.push("Caracteres especiales no permitidos");
      return errors;
    },
  },
  methods: {
    handlerFuntionsFtn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          message: "Por favor ingresar todos los datos requeridos",
          show: true,
          type: "error",
        });
      } else {
        switch (this.$route.meta.type_action) {
          case "create":
            this.addInsumoFtn();
            break;
          case "edit":
            this.updateInsumoFtn();
            break;
          default:
            break;
        }
      }
    },

    // funciones
    async addInsumoFtn() {
      const body_param = {
        id_solicitud_necesidad: this.segm_obj_var.id_solicitud_necesidad,
        id_obs: this.producto_var,
        id_unidad_medida: this.unidad_med_var,
        cantidad: this.cantidad_var,
        precio_unitario: Number(this.precio_unit_var),
        especificacion: this.especificacion_var,
      };

      const { data, status } =
        await this.services.ProcesoReserva.postInsumoSolicitud(
          this.$route.params.id_solicitud,
          body_param
        );


      if (status === 201) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });
        this.$router.back();
      } else {
        this.temporalAlert({
          message: "Error al agregar insumo",
          show: true,
          type: "error",
        });
      }
    },

    async updateInsumoFtn() {
      const body_param = {
        id_unidad_medida: this.unidad_med_var,
        cantidad: this.cantidad_var,
        precio_unitario: Number(this.precio_unit_var),
        especificacion: this.especificacion_var,
      };

      // comparar con snapshot con body_param
      const isEquals =
        JSON.stringify(this.SNAPSHOT_CONST) === JSON.stringify(body_param);
      if (isEquals) {
        this.temporalAlert({
          message: "No se han realizado cambios",
          show: true,
          type: "info",
        });
        return;
      } else {

        const { data, status } =
          await this.services.ProcesoReserva.putInsumoSolicitud(
            this.$route.params.id_insumo,
            this.id_detalle_var,
            body_param
          );


        if (status === 200) {
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });
        } else {
          this.temporalAlert({
            message: "Error al actualizar insumo",
            show: true,
            type: "error",
          });
        }
        this.$router.back();
      }
    },

    async FetchDetailInsumo() {

      const { data, status } =
        await this.services.SolicitudCompra.getDetailInsumoSolicitudCompra(
          this.$route.params.id_solicitud,
          this.$route.params.id_insumo
        );
      if (status === 200) {
        this.info_obs_var = data.detalle_obs;
        this.unidad_med_var = data.id_unidad_medida;
        this.cantidad_var = data.solicitud_compra_detalle_obs[0].cantidad;
        this.precio_unit_var = data.precio_unitario;
        this.$refs.money_txt_ref.updateValue(this.precio_unit_var);
        this.especificacion_var = data.observacion;
        this.id_detalle_var = data.solicitud_compra_detalle_obs[0].id;
        this.id_estado_insumo = data.id_estado;

        this.SNAPSHOT_CONST = {
          id_unidad_medida: this.unidad_med_var,
          cantidad: this.cantidad_var,
          precio_unitario: Number(this.precio_unit_var),
          especificacion: this.especificacion_var,
        };
      }

    },

    async FetchCtlTipoObsFtn() {

      const { data, status } = await this.services.Obs.getObsCategoriaList();
      if (status === 200) {
        this.ctl_tipo_obs_var = data;
      }

    },

    async FetchCtlSegmObjFtn() {

      if (this.tipo_obs_var != null) {
        const { data, status } =
          await this.services.SolicitudCompra.getCtlSegmentoObs(
            this.$route.params.id_proceso,
            { id_tipo_obs: this.tipo_obs_var }
          );
        if (status === 200) {
          this.ctl_segm_obj_var = data;
        }
      } else {
        this.ctl_segm_obj_var = null;
      }

    },

    async FetchCtlProductoFtn() {

      if (this.segm_obj_var != null) {
        const { data, status } =
          await this.services.SolicitudCompra.getCtlObsBySegmento(
            this.$route.params.id_proceso,
            this.segm_obj_var.id
          );
        if (status === 200) {
          this.ctl_producto_var = data;
        }
      } else {
        this.ctl_producto_var = null;
      }

    },

    async FetchCtlUnidadMedFtn() {

      const { data, status } =
        await this.services.Presentations.getListPresentations();
      if (status === 200) {
        this.ctl_unidad_med_var = data;
      }

    },
  },

  created() {
    if (this.$route.meta.type_action == "create") {
      this.FetchCtlTipoObsFtn();
    } else {
      this.FetchDetailInsumo();
    }
    this.FetchCtlUnidadMedFtn();
  },
};
</script>